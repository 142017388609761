import React from 'react';
import { SocialIconProps } from './types';

const SocialIcon: React.FC<SocialIconProps> = ({ href, icon }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="transition-colors duration-300 ease-in-out mx-2 first:ml-0 last:mr-0"
    >
      <div className="w-6 h-6 text-[#262626] hover:text-[#F05A28]">
        {React.cloneElement(icon as React.ReactElement, {
          className: 'w-full h-full fill-current'
        })}
      </div>
    </a>
  );
};

export default SocialIcon;