import React from 'react';
import { LogoProps } from '../types';

const ShimmeringLogo: React.FC<LogoProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 530.83 305.22"
    className={className}
  >
    <defs>
      <filter id="shimmer">
        <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
          result="shimmer"
        />
        <feBlend in="SourceGraphic" in2="shimmer" mode="screen" />
      </filter>
    </defs>
    
    {/* Black text parts of the logo */}
    <path
      fill="#262626"
      d="M69.23,220.35c-2.3-4.45-5.79-8.09-10.46-10.92-4.68-2.83-10-4.25-15.99-4.25-2.76,0-5.52.31-8.28.92-2.76.61-5.21,1.61-7.36,2.99-2.15,1.38-3.87,3.14-5.17,5.29-1.3,2.15-1.96,4.68-1.96,7.59,0,5.06,2.07,8.93,6.21,11.62,4.14,2.68,10.66,4.94,19.55,6.79,12.73,2.61,22.12,6.4,28.17,11.38,6.06,4.98,9.08,11.69,9.08,20.12,0,6.13-1.19,11.31-3.56,15.52-2.38,4.22-5.48,7.67-9.32,10.35-3.83,2.68-8.17,4.6-13,5.75-4.83,1.15-9.7,1.72-14.6,1.72-8.13,0-16.02-1.69-23.69-5.06-7.67-3.37-13.95-8.66-18.86-15.87l12.42-9.2c2.76,4.75,6.82,8.74,12.19,11.96,5.37,3.22,11.27,4.83,17.71,4.83,3.37,0,6.59-.31,9.66-.92,3.07-.61,5.75-1.69,8.05-3.22,2.3-1.53,4.14-3.49,5.52-5.87,1.38-2.38,2.07-5.33,2.07-8.86,0-5.67-2.72-9.93-8.17-12.76-5.45-2.83-13.3-5.48-23.58-7.93-3.22-.77-6.63-1.73-10.24-2.88-3.6-1.15-6.94-2.84-10-5.06-3.07-2.22-5.6-5.02-7.59-8.39-1.99-3.37-2.99-7.59-2.99-12.65,0-5.52,1.07-10.24,3.22-14.14,2.15-3.91,5.02-7.13,8.62-9.66,3.6-2.53,7.71-4.41,12.31-5.63,4.6-1.23,9.35-1.84,14.26-1.84,7.51,0,14.8,1.69,21.85,5.06,7.05,3.37,12.34,8.13,15.87,14.26l-11.96,8.97Z"
    />
    <path fill="#262626" d="M129.95,302.46h-15.64v-107.64h15.64v107.64Z" />
    <path
      fill="#262626"
      d="M243.11,205.86l-64.4,83.03h66.7v13.57h-87.4v-11.04l64.17-83.49h-60.95v-13.11h81.88v11.04Z"
    />
    <path
      fill="#262626"
      d="M344.76,205.86l-64.4,83.03h66.7v13.57h-87.4v-11.04l64.17-83.49h-60.95v-13.11h81.88v11.04Z"
    />
    <path fill="#262626" d="M390.99,302.46h-15.64v-150.06h15.64v150.06Z" />
    <path
      fill="#262626"
      d="M530.83,245.42v3.45c0,1.23-.08,2.53-.23,3.91h-89.24c.15,5.37,1.23,10.43,3.22,15.18,1.99,4.75,4.75,8.89,8.28,12.42,3.53,3.53,7.67,6.33,12.42,8.4,4.75,2.07,9.89,3.1,15.41,3.1,8.12,0,15.14-1.88,21.04-5.63,5.9-3.76,10.39-8.09,13.46-13l11.73,9.2c-6.44,8.13-13.53,13.95-21.27,17.48-7.75,3.53-16.06,5.29-24.96,5.29-7.97,0-15.37-1.42-22.2-4.25-6.82-2.83-12.69-6.79-17.59-11.84-4.91-5.06-8.78-11.04-11.62-17.94-2.84-6.9-4.25-14.41-4.25-22.54s1.38-15.64,4.14-22.54c2.76-6.9,6.59-12.88,11.5-17.94,4.91-5.06,10.66-9.01,17.25-11.84,6.59-2.84,13.72-4.26,21.39-4.26,8.43,0,15.87,1.42,22.31,4.26,6.44,2.84,11.81,6.67,16.1,11.5,4.29,4.83,7.55,10.46,9.77,16.9,2.22,6.44,3.33,13.34,3.33,20.7ZM514.96,240.36c-.46-10.12-3.72-18.52-9.77-25.19-6.06-6.67-14.68-10-25.88-10-5.21,0-10.04.96-14.49,2.88-4.45,1.92-8.36,4.52-11.73,7.82-3.38,3.3-6.06,7.05-8.05,11.27-1.99,4.22-3.14,8.62-3.45,13.23h73.37Z"
    />
    {/* Orange flame-like shapes with shimmering effect */}
    <g filter="url(#shimmer)" className="flame-group">
      <path
        fill="#f05a28"
        d="M241.68,32.87s-21.96,26.21,9.56,57.38c31.52,31.17,14.88,59.15-15.58,72.25,0,0,33.64-33.65-1.42-61.27-35.06-27.63-5.66-65.88,7.44-68.36Z"
      />
      <path
        fill="#f05a28"
        d="M208.02,69s-15.84,18.91,6.9,41.39c22.74,22.48,10.73,42.66-11.24,52.12,0,0,24.26-24.27-1.03-44.2-25.29-19.93-4.08-47.52,5.37-49.31Z"
      />
      <path
        fill="#f05a28"
        d="M281.14,0s-27.53,32.86,11.99,71.93,18.65,74.15-19.54,90.57c0,0,42.17-42.18-1.78-76.81C227.86,51.06,264.71,3.11,281.14,0Z"
      />
    </g>
    <style>
      {`
        @keyframes shimmer {
          0% { opacity: 1; }
          50% { opacity: 0.7; }
          100% { opacity: 1; }
        }
        .flame-group {
          animation: shimmer 2s infinite;
        }
      `}
    </style>
  </svg>
);

export default ShimmeringLogo;