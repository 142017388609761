/* AFTER CLEAN

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyCbf9UN6fGIaM9o0iEKqmZTd8rn4J7IV8Q",
  authDomain: "sizzle-landing-3d452.firebaseapp.com",
  projectId: "sizzle-landing-3d452",
  storageBucket: "sizzle-landing-3d452.appspot.com",
  messagingSenderId: "367406633546",
  appId: "1:367406633546:web:b8c1300727f637ccbe4cb3",
  measurementId: "G-XJH98PJQQL"
};

// Initialize Firebase app with the configuration
const app = initializeApp(firebaseConfig);

// Initialize Firestore database
const db = getFirestore(app);

// Initialize Firebase Functions
const functions = getFunctions(app);

// If you're using the Firebase emulator, uncomment the following line:
// connectFunctionsEmulator(functions, "localhost", 5001);

// Export the Firestore database instance and functions for use in other parts of the app
export { db, functions };
*/

//1ST ATTEMPT

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCbf9UN6fGIaM9o0iEKqmZTd8rn4J7IV8Q",
  authDomain: "sizzle-landing-3d452.firebaseapp.com",
  projectId: "sizzle-landing-3d452",
  storageBucket: "sizzle-landing-3d452.appspot.com",
  messagingSenderId: "367406633546",
  appId: "1:367406633546:web:b8c1300727f637ccbe4cb3",
  measurementId: "G-XJH98PJQQL"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);