import React from 'react';
import SocialIcon from '../SocialIcon';
import XIcon from '../icons/XIcon';
import DiscordIcon from '../icons/DiscordIcon';

const SocialHeader: React.FC = () => {
  return (
    <header className="p-4 flex justify-end">
      <div className="flex items-center space-x-4">
        <SocialIcon
          href="https://x.com/SizzleFi"
          icon={<XIcon />}
        />
        <SocialIcon
          href="https://discord.gg/6RPZHqvfjy"
          icon={<DiscordIcon />}
        />
      </div>
    </header>
  );
};

export default SocialHeader;