import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import EmailForm from './components/EmailForm';
import SocialHeader from './components/SocialHeader';
import LandingLogo from './components/LandingLogo';

const App: React.FC = () => {
  const handleSubmit = async (email: string, token: string) => {
    try {
      const app = getApp();
      const functions = getFunctions(app);
      const addUserFunction = httpsCallable(functions, 'addUser');
      const result = await addUserFunction({ email, recaptchaToken: token });
      const data = result.data as { message: string, userId: string };
      console.log(data.message);
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      if (error instanceof Error) {
        throw new Error(`Error adding email: ${error.message}`);
      } else {
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <div className="flex flex-col min-h-screen">
        {/* Top Section: Social Media Icons */}
        <SocialHeader />

        {/* Middle Section: Logo and Headline */}
        <LandingLogo />

        {/* Bottom Section: Email Subscription Form */}
        <EmailForm onSubmit={handleSubmit} />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default App;