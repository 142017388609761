/* AFTER CLEAN

import { collection, getDoc, doc, updateDoc, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from './firestore'; // Update this import to match your Firebase config file name

export interface User {
  id?: string;
  email: string;
  walletAddress?: string;
  additionalInfo?: string;
}

const userCollection = collection(db, 'users');

export const addUser = async (user: User): Promise<string> => {
  console.log('Starting addUser function with user:', user);
  try {
    const docRef = await addDoc(userCollection, {
      ...user,
      createdAt: new Date(),
    });
    console.log('User added successfully with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error in addUser:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to add user: ${error.message}`);
    } else {
      throw new Error('An unexpected error occurred while adding user');
    }
  }
};

export const getUserById = async (id: string): Promise<User | null> => {
  console.log('Getting user by ID:', id);
  const docRef = doc(db, 'users', id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    console.log('No user found with ID:', id);
    return null;
  }
  console.log('User found:', docSnap.data());
  return { id: docSnap.id, ...docSnap.data() } as User;
};

export const getUserByEmail = async (email: string): Promise<User | null> => {
  console.log('Getting user by email:', email);
  const q = query(userCollection, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    console.log('No user found with email:', email);
    return null;
  }
  const doc = querySnapshot.docs[0];
  console.log('User found:', doc.data());
  return { id: doc.id, ...doc.data() } as User;
};

export const updateUser = async (id: string, data: Partial<User>): Promise<void> => {
  console.log('Updating user with ID:', id, 'Data:', data);
  const docRef = doc(db, 'users', id);
  try {
    await updateDoc(docRef, {
      ...data,
      updatedAt: new Date(),
    });
    console.log('User updated successfully');
  } catch (error) {
    console.error('Error updating user:', error);
    throw new Error('Failed to update user');
  }
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailRegex.test(email);
  console.log('Email validation result:', { email, isValid });
  return isValid;
};

*/

//FIRST ATTEMPT

import { collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from './firestore';

export interface User {
  id?: string;
  email: string;
  walletAddress?: string;
  additionalInfo?: string;
}

const userCollection = collection(db, 'users');
const functions = getFunctions();

export const addUser = async (user: User, recaptchaToken: string): Promise<string> => {
  console.log('Starting addUser function with user:', user);
  try {
    const addUserFunction = httpsCallable(functions, 'addUser');
    const result = await addUserFunction({ email: user.email, recaptchaToken });
    const data = result.data as { message: string; userId: string };
    console.log('User added successfully with ID:', data.userId);
    return data.userId;
  } catch (error) {
    console.error('Error in addUser:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to add user: ${error.message}`);
    } else {
      throw new Error('An unexpected error occurred while adding user');
    }
  }
};

export const getUserById = async (id: string): Promise<User | null> => {
  console.log('Getting user by ID:', id);
  const docRef = doc(db, 'users', id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    console.log('No user found with ID:', id);
    return null;
  }
  console.log('User found:', docSnap.data());
  return { id: docSnap.id, ...docSnap.data() } as User;
};

export const getUserByEmail = async (email: string): Promise<User | null> => {
  console.log('Getting user by email:', email);
  const q = query(userCollection, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    console.log('No user found with email:', email);
    return null;
  }
  const doc = querySnapshot.docs[0];
  console.log('User found:', doc.data());
  return { id: doc.id, ...doc.data() } as User;
};

export const updateUser = async (id: string, data: Partial<User>): Promise<void> => {
  console.log('Updating user with ID:', id, 'Data:', data);
  const docRef = doc(db, 'users', id);
  try {
    await updateDoc(docRef, {
      ...data,
      updatedAt: new Date(),
    });
    console.log('User updated successfully');
  } catch (error) {
    console.error('Error updating user:', error);
    throw new Error('Failed to update user');
  }
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailRegex.test(email);
  console.log('Email validation result:', { email, isValid });
  return isValid;
};