import React from 'react';
import ShimmeringLogo from '../icons/ShimmeringLogo';

const LandingLogo: React.FC = () => {
  return (
    <main className="flex-grow flex flex-col items-center justify-center p-4">
      <ShimmeringLogo className="w-64 h-64 mb-8" />
      <h1 className="text-4xl text-custom-black font-bold text-center mb-8">
        A New DeFi Experience Coming Soon
      </h1>
    </main>
  );
};

export default LandingLogo;